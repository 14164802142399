import React from 'react';
import './App.css';

// Track record data
const trackRecordItems = [
  {
    token: '$GNON',
    entry: '$12.8M mcap',
    peak: '$389M mcap',
    multiplier: '30.4x'
  },
  {
    token: 'PROJECT89',
    entry: '$10M mcap',
    peak: '$122M mcap',
    multiplier: '12.2x'
  },
  {
    token: '$AIXBT',
    entry: '$5M mcap',
    peak: '$22M mcap',
    multiplier: '4.4x'
  }
];

function App() {
  return (
    <div className="App">
      <header className="hero">
        <div className="fea">
          <div className="hero-content">
            <span className="badge">In Crypto Since 2017</span>
            <h1>SATO<span className="accent">SPEK</span></h1>
            <p className="hero-text">
              We combine systematic analysis with elite pattern recognition
              <span className="accent"> to spot market opportunities early.</span>
            </p>
            <p className="subtitle">AI & Crypto market intelligence for serious investors.</p>
          </div>
        </div>
      </header>

      <section className="track-record">
        <span className="badge">Track Record</span>
        <div className="track-record-grid">
          {trackRecordItems.map((item, index) => (
            <div key={index} className="record-card">
              <h3 className="accent">{item.token}</h3>
              <div className="multiplier accent">{item.multiplier}</div>
              <div className="record-details">
                <p>Entry: {item.entry}</p>
                <p className="accent">Peak: {item.peak}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
      
      <section className="team">
        <span className="badge">The Team</span>
        <div className="team-grid">
          <div className="team-card">
            <div className="team-image-container">
              <img 
                src="./images/xiao330.jpg"
                alt="Dr. Guo Xiaochun" 
                className="team-image" 
              />
            </div>
            <h3 className="accent">Dr. Guo Xiaochun</h3>
            <p className="title">Quantitative Strategy</p>
            <div className="divider"></div>
            <p className="description">
              Developed systematic frameworks for crypto market analysis. Specializes in 
              quantitative modeling and risk management for emerging narratives.
            </p>
          </div>

          <div className="team-card">
            <div className="team-image-container">
              <img 
                src="./images/petko330.jpg"
                alt="Petko Donev" 
                className="team-image" 
              />
            </div>
            <h3 className="accent">Petko Donev</h3>
            <p className="title">Market Intelligence</p>
            <div className="divider"></div>
            <p className="description">
              Specializes in early pattern recognition of market narratives and psychology shifts. 
              Focus on AI/crypto convergence opportunities and strategic positioning.
            </p>
          </div>
        </div>
      </section>

      <section className="our-edge">
        <span className="badge">Our Edge</span>
        <div className="edge-grid features-grid">
          <div className="edge-card feature-card">
            <h3 className="accent">Pattern Recognition</h3>
            <p className="description">
            Get positioned before major market moves. Our proven track record shows 
            consistent early entry into winning trends, giving you significant advantage.
            </p>
          </div>
          <div className="edge-card feature-card">
            <h3 className="accent">Risk Management</h3>
            <p className="description">
            Clear signals backed by rigorous analysis. Know exactly when to enter, 
            how to position, and when to exit with our systematic approach.
            </p>
          </div>
          <div className="edge-card feature-card">
            <h3 className="accent">Strategic Intelligence</h3>
            <p className="description">
            Benefit from professional-grade market intelligence and analysis. 
            Make informed decisions based on deep market understanding others miss.
            </p>
          </div>
        </div>
      </section>

      <section className="cta-section">
        <h2>Join Founding Members</h2>
        <p className="subtitle">Early access to our systematic approach</p>
        <button className="cta-button primary large">Request Access</button>
      </section>

      <footer>
        <p>© 2024 SATOSPEK. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;